<template>
  <div class="flex justify-center">
    <div class="mt-48">
      <h2 class="text-3xl font-bold text-center pb-12">Company Profile</h2>
      <el-form
        ref="companyProfile"
        :model="companyProfile"
        :rules="rules"
        class="lato pb-12"
      >
        <el-form-item prop="name" class="mb-4">
          <p class="text-lg font-semibold pb-3">Company Name</p>
          <el-input v-model="companyProfile.name"></el-input>
        </el-form-item>
        <el-form-item prop="websiteURL" class="mb-4">
          <p class="text-lg font-semibold pb-3">Company Website URL</p>
          <el-input v-model="companyProfile.websiteURL"></el-input>
        </el-form-item>
        <el-form-item prop="address" class="mb-4">
          <p class="text-lg font-semibold pb-3">Company Address</p>
          <el-input v-model="companyProfile.address"></el-input>
        </el-form-item>
         <el-form-item prop="description" class="mb-4">
          <p class="text-lg font-semibold pb-3">Company Description</p>
          <el-input
            v-model="companyProfile.description"
            type="textarea"
            :rows="5"
            :maxlength="256"
          ></el-input>
        </el-form-item>
        <el-form-item prop="imageFile" class="mb-4">
          <p class="text-lg font-semibold pb-3">Company logo</p>
          <div class="border border-maingrey p-3 relative company_logo">
            <input
              type="file"
              name="upload"
              id="upload"
              class=""
              placeholder="Upload File"
              @change="profileUpload"
              ref="fileInput"
              enctype="multipart/form-data"
              accept=".jpg, .png"
            > 
            <div class="flex justify-between items-center">
              <p>{{ file_name ? file_name : 'No file chosen '}}</p>
              <p style="color: #48BFE3;" class="font-bold">{{ loader ? 'Uplaoding...' : 'Choose file' }}</p>
            </div>
          </div>
          <div class="flex justify-center py-12">
            <dummy-avatar v-if="showImage === false"></dummy-avatar>
            <img :src="companyProfile.imageFile" alt="" v-else class="w-40 h-40 rounded-full" />
          </div>
          <div class="w-10/12 mx-auto">
            <button
              @click.prevent="handleCompanyProfileUpdate"
              class="bg-primary mt-6 py-3 w-full rounded-full text-white font-bold text-lg">
              {{ profile.loader ? 'Submitting...' : 'Update details' }}
            </button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dummyAvatar from '../components/dummy-avatar.vue';

export default {
  components: { dummyAvatar },
  data() {
    return {
      file_name: '',
      loader: false,
      showImage: false,
      companyProfile: {
        name: '',
        address: '',
        websiteURL: '',
        description: '',
        imageFile: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter your company name'
          }
        ],
        address: [
          {
            required: true,
            message: 'Please enter your company address'
          }
        ],
        websiteURL: [
          {
            required: true,
            message: 'Please enter your company website URL'
          }
        ],
        description: [
          {
            required: true,
            message: 'Please enter your company description'
          }
        ]
      }
    }
  },
  async created() {
    if(this.$auth.user !== undefined) {
     await this.fetchSecretKeys()
    }
  },
    computed: {
    ...mapState(['secretKeys', 'profile']),
  },
  methods: {
    ...mapActions(['updateCompanyProfile', 'fetchSecretKeys']),
    async profileUpload(e) {
      if(e.target.files) {
        const file = e.target.files[0];
        await this.handleUpload(file)
      }
    },
     async handleUpload(file) {
      this.loader = true;
      const cloudinary = {
        apiKey: this.secretKeys.cloudinaryKey,
        cloudName: this.secretKeys.cloudinaryName,
        uploadPreset: this.secretKeys.cloudinaryUploadPreset
      }
      try {
        const clodinaryURL = `https://api.cloudinary.com/v1_1/${cloudinary.cloudName}/upload`
        const formData = new FormData()
        formData.append("file", file);
        formData.append("upload_preset", cloudinary.uploadPreset);
        const response = await fetch(clodinaryURL, {
          method: 'POST',
          body:  formData,
        })
        let commits = await response.json()
        this.loader = false;
        this.companyProfile.imageFile = commits.secure_url
        this.file_name = commits.original_filename
        this.showImage = true
      } catch (e) {
        this.loader = false;
        this.showImage = false
        this.$notify.error({
          title: 'Success',
          message: `${e.response.data}`,
          type: 'error',
        })
      }
    },
    trimUserId (string){
      return string.slice(6);
    },
    handleCompanyProfileUpdate() {
      this.$refs['companyProfile'].validate((valid) => {
        if(valid) {
          const payload = {
            name: this.companyProfile.name,
            logo_url: this.companyProfile.imageFile,
            website: this.companyProfile.websiteURL,
            description: this.companyProfile.description,
            hq_address: this.companyProfile.address,
            user_id: this.trimUserId(this.$auth.user.sub)
          }
        this.updateCompanyProfile(payload)
          .then((res) => {
            if(res.status === 200) {
              this.$refs['companyProfile'].resetFields();
              this.showImage = false
              this.$message({
                message: `${res.data}`,
                type: 'success'
              });
              this.$router.push({ name: 'company-profile' })
            }
          })
          .catch((err) => {
            return err
          })
        }
      })
    }
  }
}
</script>

<style scoped>
* >>> .el-input__inner{
  width: 408px;
  height: 48px;
  background: #EEEEEE;
  border-radius: 8px;
  border: none;
}

* >>> .el-textarea__inner {
  background: #EEEEEE;
  border-radius: 8px;
  border: none;
  padding: 1rem;
}

.company_logo {
  background: #EEEEEE;
  width: 408px;
  border: none;
  border-radius: 8px;
}

input[type="file"] {
  position: absolute;
  right: 0;
  width: 12rem;
  opacity: 0;
  cursor: pointer;
}

</style>