<template>
  <div>
    <img src="@/assets/img/dummy.svg" alt="" />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>